import {
  createWebHashHistory,
  createWebHistory,
  createRouter,
} from 'vue-router';
import { auth, status } from '@/useAuth';
import mitt from '@/mitt';
import Home from './views/home/Index.vue';
import notFound from './views/404.vue';
import { ElMessageBox } from 'element-plus';
import Track from '@/utils/Track';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "Login" */ './views/Login.vue'),
  },
  {
    path: '/member',
    name: 'Member',
    component: () =>
      import(/* webpackChunkName: "Member" */ './views/member/Index.vue'),
  },
  {
    path: '/search',
    name: 'Search',
    component: () =>
      import(/* webpackChunkName: "Search" */ './views/Search.vue'),
  },
  {
    path: '/works',
    name: 'Works',
    component: () =>
      import(/* webpackChunkName: "Works" */ './views/Works.vue'),
  },
  {
    path: '/exhibs',
    name: 'Exhibs',
    component: () =>
      import(/* webpackChunkName: "Exhibs" */ './views/Exhibs.vue'),
  },

  {
    path: '/editor/:id',
    name: 'Editor.Edit',
    redirect: { name: 'Editor.Space' },
    component: () =>
      import(/* webpackChunkName: "Editor.Edit" */ './views/editor/Index.vue'),
    children: [
      {
        path: 'space',
        name: 'Editor.Space',
        meta: { full: true },
        component: () =>
          import(
            /* webpackChunkName: "Editor.Space" */ './views/editor/Space.vue'
          ),
      },
      {
        path: 'profile',
        name: 'Editor.Profile',
        meta: { full: true },
        component: () =>
          import(
            /* webpackChunkName: "Editor.Profile" */ './views/editor/Profile.vue'
          ),
      },
      {
        path: 'preview',
        name: 'Editor.Preview',
        meta: { full: true },
        component: () =>
          import(
            /* webpackChunkName: "Editor.Preview" */ './views/editor/Preview.vue'
          ),
      },
    ],
  },
  {
    path: '/room/:id/:slug',
    name: 'Room',
    meta: { full: true },
    component: () =>
      import(/* webpackChunkName: "Room" */ './views/room/Index.vue'),
  },
  {
    path: '/single/:slug',
    name: 'Single',
    meta: { full: false },
    component: () =>
      import(/* webpackChunkName: "Single" */ './views/Single.vue'),
  },
  // { path: '/:path(.*)', component: notFound },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

let pageViewInterval; // 用于存储定时器 ID

const guard = async (to, from, next) => {
  const name = to.name?.split('.')[0];
  if (!auth.checked) {
    await status();
  }

  if (pageViewInterval) {
    clearInterval(pageViewInterval); // 清除之前的定时器
  }

  if (name && !auth.isAuth && (name === 'Member' || name === 'Editor')) {
    ElMessageBox.alert('權限不足', { center: true }).then(() => {
      if (from) {
        next({ name: from.name });
      } else {
        window.location.href = '/';
      }
    });
  } else {
    const gtag = () => {
      if (!window.gtag) return;
      console.log('pageview');
      window.gtag('config', 'G-D10ENHN4V2', {
        page_title: to.meta.title || document.title,
        page_location: window.location.origin + to.fullPath,
        page_path: to.fullPath,
      });
    };

    if (to.name === 'Room') {
      gtag();
      pageViewInterval = setInterval(() => {
        gtag();
      }, 5 * 60 * 1000); // 5 分钟 = 300,000 毫秒
    }

    // 清除Track的interval
    Track({
      ea: 'open',
      ec: to.name,
      el: to.params?.id,
    });
    next();
  }
};

router.beforeEach((to, from, next) => {
  guard(to, from, next);
});

export default router;
